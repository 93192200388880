body {
  margin: 0;
  font-family: 'Helvetica Neue',Arial,Helvetica,sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
      overflow: hidden;
      background-image: url("images/splash_tinted.jpg")!important;
      background-size: cover!important;
}

#root .page {
height: 100vh;
background-color: #FFF;
}

#root .page .title {
  background-image: url("images/artwork.jpg");
  background-size: 100% auto;
  background-position: center;
  padding: 0;
  color: #ffffff;
  display: flex;
  align-items: center;
  z-index: 200;
  justify-content: space-between; margin: 0!important;
  height: 25vh;
  max-height: 25vh;
}

.round-logos { display: flex;    height: 90%; align-items: flex-end;     padding: 0 10px;}
.round-logos>.round-logo { height: 120px; margin: 0 1vw 0 0; max-height: 16vh;}
.round-logos img {border-radius:50%;max-height: 100%; }
.logo-link img {cursor: pointer;}


#root a{color: #792B26; text-decoration: underline;}
#root .imageCredits a{color: #c38d8a; }

#root .page .menu { border: 0; background-color: #231d26; z-index: 202;    margin: 0; font-family: 'Helvetica Neue',Arial,Helvetica,sans-serif; height: 5vh; max-height: 5vh;border-radius: 0;}
#root .filter .ui.search.dropdown .menu{height:auto;    max-height: inherit;}
#root .ui.menu .item {color: #FFF; padding-top: 10px; padding-bottom: 10px; }
#root .ui.menu>.item:first-child {border-radius:0;}
#root .ui.menu .active.item {background-color: #000;}
#root .ui.menu a{text-decoration: none;}


#root .pageContainer{    height: 70vh; overflow-x: hidden;     overflow-y: scroll; }
.contentSegment{   padding: 20px; background: #fffffff0; margin: 20px;}
.contentSegmentMarginBottom{margin-bottom: 10vh;padding-bottom: 50px;}
.contentSegmentRow{display: flex;align-items: center;}
#root a.searchButton{    border-radius: 50%;
    background-color: #267479;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: bold;
    margin-left: 50px;}

#root h1{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  margin: 0;
  background-color: #792B26;
  padding: 20px 40px;
  font-size: 32px;
  width: 50%;
}

#root .pageContainer h1{color: #ad4440;  margin: 0 0 .5em 0;}

#root .credits {  padding-top: 1em; display:flex; flex-direction: column; background-color: #231D26; color:#FFF;margin-bottom: 10vh;}
#root .imageCredits {  display:flex; }
.creditsImage{height:155px; width:155px; margin: 0 1em 0 0;}
.ilaImage{height:100px; width:auto; margin: 0 0 0 1em; border:5px solid #FFF;}

#root .marginBottom{margin-bottom:20px;}
.personDiv{    background: #f3f3f3f0; padding: 1em; margin-bottom: 20px;  }
#root .infoCircle, #root .fnCircle{    color: #792B26!important;}

#root .fnCircle{    display: block;
    float: left;
    vertical-align: middle;
    line-height: 1.2em;
    font-size: 1em;}




.peopleContainer{display: flex; margin-bottom: 10vh;}
.leftCol {flex:1; padding-right:1em;}
.rightCol{flex:3; padding-left: 1em;}
.sortButton{display: none;}
.skillsCheckboxes{margin-top: 1em;}
.skillsInput{max-width:90vw;}
.checkboxAll{margin: 1em 0; font-weight: bold;}
.checkboxSkill{margin: .2em 0;}
.checkboxSkillNumber{padding-left: 5px;}
#root .infoCircle{margin-left:5px;}
.peopleContainer .personDiv:last-child{margin-bottom: 0;}
#root .backCircle{margin: -30px 0 0 -30px;}
.personName{ font-weight: bold;}
.personSkillsDiv{margin: 10px 0; border-left: 2px solid #ad4440; padding: 5px 5px 5px 10px; background-color: #00000017; }
.personSkills{font-style: italic;}
.personSkillsBold{font-style: italic; font-weight: bold;}
#root .page .filter .menu {background-color: #fff;}

#root .ui.card{padding: 2em; width: auto;}
#root .item{padding:1em;}
#root .list .item{padding: .5em 1em .5em 0;}
#root .filter {     margin-bottom: 2em;}
#root .filter .ui.input{width: 200px; max-width:100%;}
#root .filter .ui.search.dropdown{width: 100%; margin-top: 1em; border-radius:0; padding: .5em 0; cursor: pointer;}
#root .filter .ui.input>input{border-radius: 0; padding-top: 1em; padding-bottom: 1em; font-family: 'Helvetica Neue',Arial,Helvetica,sans-serif;}
#root .filter .ui.label{background-color: #ad4440; margin: 0 .5em; color: #FFF; text-decoration: none;}
#root .filter .ui.label i{color: #231d26; opacity: 1;   margin-left: 1em;}
#root .ui.multiple.search.dropdown>.text {color: #231d26;}

#root .frameContainer{    overflow-y: hidden;}
.formFrame{height:100%; width:100%;}

#root .ui.container{width:70vw!important;}



@media only screen and (max-width: 500px) {
.rightCol{flex:1; padding-left: 0; margin-right:1em;}
.personDiv { margin-bottom: 10px; }
#root .filter .ui.input{width: 150px; }
.contentSegment{margin: 0;}
#root .backCircle{margin:0;}
}

@media only screen and (max-width: 770px) {
  #root .ui.container{margin-left: 0!important; margin-right: 0!important;     width: auto!important;}
  #root .imageCredits {flex-direction: column; align-items: center;}
  .creditsImage {margin-bottom:1em;}
  .ilaImage {margin-top:1em;}
  #root h1{font-size: 14px; width: auto; }
  .round-logos { height: 100%; padding:0;
    align-items: center;
    width: 100%;
    justify-content: flex-end;}
    .round-logos>.round-logo {height: 80px;}
    .contentSegmentRow { flex-direction: column;}
    #root a.searchButton {margin-left: 0;    margin-top: 40px;}
}
